<template>
    <admin-dashboard-layout>
        <div>
            <div>
                UPDATE EXISTING CLAIM
            </div>
            Put /api/claims<br>
            header auth: XXXXXX<br>
            BODY<br>
            {
                "Assignment Id": "EBC40210",
                "Date Contacted": "2/2/2022",
                "Date Inspected": "2/2/2022",
                "Scheduled Appointment": "2/2/2022",
                "Inspector Name": "Gregory Shein",
                "Original ESX (ESX file)": "https://drive.google.com/drive/folders/1WETel6K-i2og4Dc_cVdLcrIKlYAe_dWh",
                "Final Report PDF (PDF File)": "https://drive.google.com/drive/folders/1WETel6K-i2og4Dc_cVdLcrIKlYAe_dWh",
                "Photo Report PDF (PDF File)": "https://drive.google.com/drive/folders/1WETel6K-i2og4Dc_cVdLcrIKlYAe_dWh"
            }
        </div>
    </admin-dashboard-layout>
</template>

<script>

    import AdminDashboardLayout from "../../layouts/AdminDashboardLayout";

    export default {
        name: "Index",
        components: {AdminDashboardLayout},
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
